//System Imports
import { useEffect } from 'react';
//UTIL imports
import NotificationLayer from 'com/util/NotificationLayer';
//WIDGET imports
import CustomerSupport from 'com/widgets/CustomerSupport';
//ICON imports
import IPEER_LOGO_WHITE from 'assets/images/ipeer_logo_white.svg';
//Style imports
import './style.css';

const AuthTemplateIPeer = (props) => {
	useEffect(() => {
		loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`, function () {
			console.log('Script loaded!');
		});
	}, []);

	const loadScriptByURL = (id, url, callback) => {
		const scriptExists = document.getElementById(id);
		if (!scriptExists) {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = url;
			script.id = id;
			script.onload = function () {
				if (callback) callback();
			};
			document.querySelector('.auth-template-ipeer').appendChild(script);
		}
		if (scriptExists && callback) callback();
	};

	return (
		<>
			<div className="auth-template-ipeer">
				<div className={`auth-template-ipeer__welcome-container`}>
					<img className="auth-template-ipeer__welcome-container-logo" src={IPEER_LOGO_WHITE} alt="logo" />
					<h1 className="auth-template-ipeer__welcome-container-header">
						<span>Welcome</span>&nbsp;
						<span>to iPeer.</span>
					</h1>
					<p className="auth-template-ipeer__welcome-container-message">
						Our mission is to empower IP professionals to collaborate with their global agents and partners in a way that strengthens their business performance. iPeer provides solutions
						that lets you make data-driven decisions when managing and instructing work to your global partners. iPeer is free to use, with options for additional access to powerful
						premium tools and analysis.
					</p>
				</div>
				<div className={`auth-template-ipeer__content`}>
					<CustomerSupport />
					{props.children}
				</div>
			</div>
			<NotificationLayer />
		</>
	);
};

export default AuthTemplateIPeer;
