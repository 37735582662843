import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import Checkbox from 'com/ui/Checkbox';
import Textarea from 'com/ui/Textarea';
import DataTable from 'com/widgets/DataTable';
import Input from 'com/ui/Input';
import AttachmentsTable from '../AttachmentsTable';
import { RoleBox } from 'com/util/RoleBox';

import { CaseRegionStatus, ROLE_TYPES, QUOTES_LOCATION, EXTERNAL_ROLES, INTERNAL_ROLES } from 'data/constants';
import CasesRegions from 'services/rest/cases-regions';
import QuotesRegions from 'services/rest/quotes-regions';
import Cases from 'services/rest/cases';
import CasesDetails from 'services/rest/cases_details';
import Users from 'services/rest/users';
import Documents from 'services/rest/documents';
import Storage from 'services/rest/storage';
import EventLoggerEvents from 'services/rest/event_logger';
import { formatEmailsStringToJSON } from 'services/strings';

import { DOCUMENT_OBJECT_TYPE } from 'data/constants';

import { isoStringToMySQLDate, toENCADateString } from 'services/dates';

import { getFlag } from 'data/flags';

import './style.css';
import useQuery from 'services/hooks/useQuery';
import useNotification from 'services/hooks/use_notification';

const InstructModal = (props) => {
	const tableHeadersInit = [
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Client Ref. No.', field: 'client_ref_numer', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: '', type: '', sort: 'none', sortable: false },
	];

	const tableHeadersInitSaas = [
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Client Ref. No.', field: 'client_ref_numer', type: 'string', sort: 'none', sortable: false },
		{ title: 'Comments For Your Agent', field: 'comment_for_agent', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: '', type: '', sort: 'none', sortable: false },
	];

	const user = useSelector((state) => state.auth.user);

	const errorsInit = {
		no_region_specified: {
			message: '',
		},
		instruction_not_confirmed: {
			message: '',
		},
	};

	const query = useQuery();
	const queryRegions = query.get('regions');
	const selectedRegionsInit = queryRegions ? queryRegions.split(',') : [];
	const [caseRegions, setCaseRegions] = useState([]);
	const [rawCRForAttachments, setRawCRForAttachments] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [caseRegionsToInstruct, setCaseRegionsToInstruct] = useState(selectedRegionsInit);
	const [comment, setComment] = useState('');
	const [caseData, setCaseData] = useState({});
	const [caseDetails, setCaseDetails] = useState({
		client_contact_greeting: '',
		client_contact_email_for_quotes: [],
		client_contact_full_name: '',
		client_contact_bcc: [],
		client_contact_email: '',
		instruct_additional_applications: '',
	});
	const [documents, setDocuments] = useState([]);
	const [confirmInstruct, setConfirmInstruct] = useState(false);
	const [errors, setErrors] = useState(errorsInit);
	// objectType state is used to determine init objectType for documents
	const [objectType, setObjectType] = useState('');
	const location = useLocation();
	const history = useHistory();
	const { id } = useParams();
	const additionalInfoRef = useRef(null);
	const [activeIndex, setActiveIndex] = useState(1);
	const sendNotification = useNotification();

	useEffect(() => {
		EventLoggerEvents.EventLogger({ event: `Instructions Form Opened`, data: { quote_id: id, user: user } });
		fetchData();
	}, []);

	const close = () => {
		if (user.roleType === ROLE_TYPES.EXTERNAL && location.pathname.includes(QUOTES_LOCATION)) {
			history.goBack();
		} else {
			let parts = location.pathname.split('/');
			parts.pop();
			history.push(parts.join('/'));
		}
	};

	function toggleRegionForInstruct(e) {
		if (e.target.name === 'all' && e.target.checked) {
			if (errors.no_region_specified.message) {
				setErrors((prevState) => {
					return { ...prevState, no_region_specified: { message: '' } };
				});
			}
			let cr = [
				...caseRegions.map((cr) => {
					return {
						id: cr.id,
						region_id: cr.region_id,
						region_name: cr.region_name,
						region_code: cr.region_code,
						associate_id: cr.associate_id,
						client_ref_number: cr.client_ref_number,
						comment_for_agent: cr.comment_for_agent,
						oon: cr.oon_associate,
					};
				}),
			];
			cr = cr.sort((a, b) => a.region_name.localeCompare(b.region_name));
			return setCaseRegionsToInstruct(cr);
		}
		if (e.target.name === 'all' && !e.target.checked) {
			return setCaseRegionsToInstruct([]);
		}
		const crById = caseRegions.find((cr) => cr.id === e.target.value);
		if (e.target.checked) {
			let cr = [
				...caseRegionsToInstruct,
				{
					id: crById.id,
					region_id: crById.region_id,
					region_name: crById.region_name,
					region_code: crById.region_code,
					associate_id: crById.associate_id,
					client_ref_number: crById.client_ref_number,
					comment_for_agent: crById.comment_for_agent,
					oon: crById.oon_associate,
				},
			];
			cr = cr.sort((a, b) => a.region_name.localeCompare(b.region_name));
			setCaseRegionsToInstruct(cr);
		}
		if (!e.target.checked) {
			setCaseRegionsToInstruct([...caseRegionsToInstruct.filter((cr) => cr.id !== e.target.value)]);
		}

		if (errors.no_region_specified.message && e.target.checked) {
			setErrors((prevState) => {
				return { ...prevState, no_region_specified: { message: '' } };
			});
		}
	}

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let c = await Cases.GetOne(id);
			setCaseData(c);
			let urlParts = location.pathname.split('/');
			let documentsData = [];

			switch (urlParts[1]) {
				case 'case-details':
					let cres = await CasesRegions.GetAllByCaseId(id, {});
					let crUninstructed = cres.data.filter((cr) => cr.status === CaseRegionStatus.UNINSTRUCTED);
					setCaseRegions(crUninstructed);
					setRawCRForAttachments(
						cres.data.map((cr) => {
							// id here is used in AttachmentsTable as id, so not improving it
							return { name: cr.region_name, id: cr.region_id, object_id: cr.id };
						}),
					);
					documentsData = await Documents.getByCase(id);
					setObjectType(DOCUMENT_OBJECT_TYPE.CASE);
					break;
				case 'quotes':
					let qres = await QuotesRegions.GetAllByQuoteId(id, {});
					let qrUninstructed = qres.data.filter((cr) => cr.status === CaseRegionStatus.UNINSTRUCTED);
					setCaseRegions(qrUninstructed);
					setRawCRForAttachments(
						qres.data.map((cr) => {
							// id here is used in AttachmentsTable as id, so not improving it
							return { name: cr.region_name, id: cr.region_id, object_id: cr.id };
						}),
					);
					setObjectType(DOCUMENT_OBJECT_TYPE.CASE);
					documentsData = await Documents.getByCase(id);
					break;
				default:
					break;
			}

			setDocuments((prevState) => {
				// sort documents by region name
				documentsData.sort((a, b) => {
					if (!a.region_name) {
						return -1;
					}
					const currentName = a.region_name.toLowerCase();
					const nextName = b.region_name.toLowerCase();
					if (currentName < nextName) {
						return -1;
					}
					if (currentName > nextName) {
						return 1;
					}

					return 0;
				});
				return [
					...documentsData.map((d) => {
						d.is_uploaded = true;
						return d;
					}),
				];
			});

			// case details fetch needs to be last because it can throw (404 not found) and code execution will jump to the catch blok
			let caseDetailsData = await CasesDetails.GetByCaseID(id);
			let client_contact_greeting = caseDetailsData?.client_contact_greeting || '';
			let client_contact_email_for_quotes = caseDetailsData?.client_contact_email_for_quotes || '';
			let client_contact_full_name = caseDetailsData?.client_contact_full_name || '';
			let client_contact_bcc = caseDetailsData?.client_contact_bcc || '';
			let client_contact_email = caseDetailsData?.client_contact_email || '';
			if (!client_contact_greeting || !client_contact_email_for_quotes || !client_contact_full_name || !client_contact_email) {
				let user = await Users.GetOne(c.client_id);
				if (user) {
					client_contact_greeting = client_contact_greeting ? client_contact_greeting : user.first_name;
					client_contact_email_for_quotes = client_contact_email_for_quotes ? client_contact_email_for_quotes : user.email_for_quotes;
					client_contact_full_name = client_contact_full_name ? client_contact_full_name : `${user.first_name} ${user.last_name}`;
					client_contact_email = client_contact_email ? client_contact_email : user.email;
				}
			}
			setCaseDetails({
				...caseDetailsData,
				client_contact_greeting: client_contact_greeting,
				client_contact_email_for_quotes: client_contact_email_for_quotes,
				client_contact_full_name: client_contact_full_name,
				client_contact_bcc: client_contact_bcc,
				client_contact_email: client_contact_email,
			});
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const instruct = async () => {
		if (!confirmInstruct) {
			if (additionalInfoRef.current) additionalInfoRef.current.scrollIntoView({ behavior: 'smooth' });
		}
		let err = validate();
		if (err) {
			return;
		}
		let d = new Date();
		let instructionDate = `${isoStringToMySQLDate(d)} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
		try {
			setShowLoader(true);
			const payload = {
				case_regions: caseRegionsToInstruct,
				comment: comment,
				start_date: instructionDate,
				instruct_additional_applications: caseDetails.instruct_additional_applications,
			};
			//documents
			await uploadDocuments();
			if (caseRegionsToInstruct.length > 0) {
				if (!caseDetails.id) {
					const detailsCreatePayload = {
						case_id: id,
						instruct_additional_applications: caseDetails.instruct_additional_applications,
						client_contact_greeting: caseDetails.client_contact_greeting,
						client_contact_email_for_quotes: formatEmailsStringToJSON(caseDetails.client_contact_email_for_quotes),
						client_contact_full_name: caseDetails.client_contact_full_name,
						client_contact_email: caseDetails.client_contact_email,
						client_contact_bcc: formatEmailsStringToJSON(caseDetails.client_contact_bcc),
					};
					await CasesDetails.Create(id, detailsCreatePayload);
				} else {
					await CasesDetails.Update(id, caseDetails.id, {
						...caseDetails,
						client_contact_email_for_quotes: formatEmailsStringToJSON(caseDetails.client_contact_email_for_quotes),
						client_contact_bcc: formatEmailsStringToJSON(caseDetails.client_contact_bcc),
						earliest_priority_date: caseDetails.earliest_priority_date ? toENCADateString(caseDetails.earliest_priority_date) : null,
						intl_filing_date: caseDetails.intl_filing_date ? toENCADateString(caseDetails.intl_filing_date) : null,
					});
				}

				let promises = [];
				// loop through case regions requested for instruction
				for (const case_region of caseRegionsToInstruct) {
					// skip if not oon
					if (!case_region.oon) continue;
					promises.push(QuotesRegions.sendEmailAgentInstructed(id, case_region));
				}
				if (promises.length) await Promise.all(promises);
				await CasesRegions.Instruct(id, payload);
				sendNotification({ type: 'success', title: 'Instructing successful' });
			}
			setShowLoader(false);
			history.push(`/case-details/${id}?reload_case_details=1`);
		} catch (error) {
			sendNotification({ type: 'error', title: 'Instruct Failed, Please verify all fields and try again.' });
			EventLoggerEvents.EventLogger({
				event: `Instruct Selected Regions Failed`,
				data: { error, user: user, case_number: caseData.case_number, instrcuted_regions: caseRegionsToInstruct.map((r) => r.region_name).join(', ') },
			});
			setShowLoader(false);
		}
	};

	const footerNext = () => {
		if (caseRegionsToInstruct.length > 0) {
			setActiveIndex(2);
		} else {
			setErrors((prevState) => {
				return { ...prevState, no_region_specified: { message: 'Please Select Regions to Instruct' } };
			});
		}
	};

	const onChangeComment = (e) => {
		setComment(e.target.value);
	};

	const onConfirmInstruct = () => {
		setConfirmInstruct(!confirmInstruct);
	};

	const onAnticipateInstructChange = () => {
		setCaseDetails((prevState) => {
			return { ...prevState, instruct_additional_applications: prevState.instruct_additional_applications ? 0 : 1 };
		});
	};

	const validate = () => {
		let err = false;
		if (!confirmInstruct) {
			setErrors((prevState) => {
				return { ...prevState, instruction_not_confirmed: { message: 'Please Confirm Instruction' } };
			});
			err = true;
		}
		return err;
	};

	const uploadDocuments = async () => {
		// filter documents that are only for update
		let documentsUpdate = documents.filter((d) => d.is_uploaded === true && d.for_update === true);
		// filter documents that are not already uploaded
		let documentsCreate = documents.filter((d) => d.is_uploaded === false);

		for (let d of documentsCreate) {
			const payload = {
				object_type: d.object_type,
				description: d.description,
				category: 'INITIAL_DOCUMENTS',
			};
			if (d.object_type === DOCUMENT_OBJECT_TYPE.CASE || d.object_type === DOCUMENT_OBJECT_TYPE.QUOTE) {
				payload.object_id = id;
			} else if (d.object_type === DOCUMENT_OBJECT_TYPE.CASE_REGION || d.object_type === DOCUMENT_OBJECT_TYPE.QUOTE_REGION) {
				const cr = rawCRForAttachments.find((crd) => crd.id === d.region_id);
				payload.object_id = cr.object_id;
			}
			await Storage.DocumentUpload([d], payload);
		}
		for (let d of documentsUpdate) {
			await Documents.UpdateByID(d.id, d);
		}
	};

	const footerActions = [
		{ primary: false, label: 'Cancel', action: close, theme: 'azami-ghost' },
		{ primary: true, label: activeIndex == 1 ? 'Next' : 'Instruct Selected Regions', action: activeIndex == 1 ? footerNext : instruct, theme: 'azami-blue' },
	];

	const goToQuoteDetails = (e) => {
		e.stopPropagation();
		history.push(`/quotes/${id}/edit`);
	};

	const onCaseRegionDataChange = (e) => {
		let case_region_id = e.target.closest('tr').dataset.id;
		setCaseRegionsToInstruct(
			caseRegionsToInstruct.map((cr) => {
				if (cr.id === case_region_id) {
					return { ...cr, [e.target.name]: e.target.value };
				} else {
					return cr;
				}
			}),
		);
	};

	const handleWizardNavClick = (e) => {
		if (e.target.dataset.index == 2 && caseRegionsToInstruct.length <= 0) {
			setErrors((prevState) => {
				return { ...prevState, no_region_specified: { message: 'Please Select Regions to Instruct' } };
			});
		} else {
			setActiveIndex(e.target.dataset.index);
		}
	};

	const contactFieldChange = (e) => {
		setCaseDetails({
			...caseDetails,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<Modal title="Instruct Region" footerActions={footerActions} closeHandler={close}>
			<div className="instruct__modal-content">
				<header className={`instruct__modal-content__header`}>
					<div
						className={`instruct__modal-content__wizard-nav-pill ${activeIndex == 1 ? 'instruct__modal-content__wizard-nav-pill-active' : ''}`}
						data-index={1}
						onClick={handleWizardNavClick}
					>
						<div className={`instruct__modal-content__wizard-nav-pill-text-wrapper ${activeIndex == 1 ? 'current-active' : ''}`}>
							<span className={`instruct__modal-content__wizard-nav-pill-number ${activeIndex == 1 ? 'active' : ''}`}>1</span>
							Regions &amp; Attachments
						</div>
						<i className={`instruct__modal-content__wizard-nav-pill-error-indicator ${errors.no_region_specified.message ? 'show-error' : ''}`}></i>
					</div>

					<div
						className={`instruct__modal-content__wizard-nav-pill ${activeIndex >= 2 ? 'instruct__modal-content__wizard-nav-pill-active' : ''}`}
						data-index={2}
						onClick={handleWizardNavClick}
					>
						<div className={`instruct__modal-content__wizard-nav-pill-text-wrapper ${activeIndex == 2 ? 'current-active' : ''}`}>
							<span className={`instruct__modal-content__wizard-nav-pill-number ${activeIndex == 2 ? 'active' : ''}`}>2</span>
							Client Regional Ref. & Contact Info
						</div>
						<i className={`instruct__modal-content__wizard-nav-pill-error-indicator ${errors.instruction_not_confirmed.message ? 'show-error' : ''}`}></i>
					</div>
				</header>
				<div className="instruct__modal-content__wizard">
					<div className={`instruct__modal-content__regions ${activeIndex == 1 ? 'show-instructing-panels' : ''}`}>
						<h4>Select the regions in which you want to instruct the case</h4>
						<Checkbox label="Select All Regions" name="all" value="all" onClick={toggleRegionForInstruct} checked={caseRegionsToInstruct.length === caseRegions.length ? true : false} />
						<div className="instruct__modal-content__regions-grid">
							<LoaderOverlay showLoader={showLoader} />
							{caseRegions.length > 0 &&
								caseRegions.map((cr) => {
									return (
										<Checkbox
											key={cr.id}
											value={cr.id}
											label={cr.region_name}
											img={getFlag(cr.region_code)}
											checked={caseRegionsToInstruct.find((cri) => cr.id === cri.id) ? true : false}
											onClick={toggleRegionForInstruct}
											labelClassName="instruct__modal-content__regions-grid__label"
										/>
									);
								})}
						</div>
						<div className="instruct__modal-content__add-regions-link">
							<RoleBox roles={['user', 'member', 'member_assistant', 'candidate', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager']}>
								<span
									onClick={goToQuoteDetails}
									title={'After you have requested a quote with additional regions, you will be able to instruct more regions from the Quotes List or Case Details.'}
								>
									Add more regions to quote
								</span>
							</RoleBox>
						</div>
						<div>
							<AttachmentsTable
								documents={documents}
								setDocuments={setDocuments}
								regions={rawCRForAttachments}
								objectTypeInit={objectType}
								spinner={showLoader}
								setSpinner={setShowLoader}
								allowDelete={false}
								checkFileType={true}
							/>
						</div>
						<RoleBox roles={['user', 'admin', 'member', 'member_assistant', 'candidate', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager']}>
							<div ref={additionalInfoRef}>
								<div className="instruct__modal-content__text-info">
									<Textarea label="Comment" onChange={onChangeComment} value={comment} />
								</div>
							</div>
						</RoleBox>
					</div>
					<div className={`instruct__modal-content__client-ref ${activeIndex == 2 ? 'show-instructing-panels' : ''}`}>
						<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
							<DataTable fixedHeader={false} columns={tableHeadersInit} customClassName="attachments__table">
								{caseRegionsToInstruct.map((cri) => {
									return (
										<tr key={cri.id} data-id={cri.id}>
											<DataTable.CountryCell code={cri.region_code}>{cri.region_name}</DataTable.CountryCell>
											<DataTable.Cell>
												<Input type="string" name="client_ref_number" value={cri.client_ref_number} onChange={onCaseRegionDataChange} />
											</DataTable.Cell>
										</tr>
									);
								})}
							</DataTable>
						</RoleBox>
						<RoleBox roles={['saas_user']}>
							<DataTable fixedHeader={false} columns={tableHeadersInitSaas} customClassName="attachments__table">
								{caseRegionsToInstruct.map((cri) => {
									return (
										<tr key={cri.id} data-id={cri.id}>
											<DataTable.CountryCell code={cri.region_code}>{cri.region_name}</DataTable.CountryCell>
											<DataTable.Cell>
												<Input type="string" name="client_ref_number" value={cri.client_ref_number} onChange={onCaseRegionDataChange} />
											</DataTable.Cell>
											<DataTable.Cell>
												<Input type="string" name="comment_for_agent" value={cri.comment_for_agent} onChange={onCaseRegionDataChange} />
											</DataTable.Cell>
										</tr>
									);
								})}
							</DataTable>
						</RoleBox>
						<div className="instruct__modal-content__client-ref-contact">
							<h4>Contact Information</h4>
							<div className="instruct__modal-content__client-ref-contact-inputs">
								<div>
									<Input type="string" name="client_contact_greeting" label="Case Contact Greeting Name" value={caseDetails.client_contact_greeting} onChange={contactFieldChange} />
								</div>
								<div>
									<Input
										type="string"
										name="client_contact_email_for_quotes"
										label='Case Contact "CC" Address(es)'
										value={caseDetails.client_contact_email_for_quotes}
										onChange={contactFieldChange}
										customClassName="instruct__modal-content__client-ref-contact-inputs__input"
									/>
								</div>
								<div>
									<Input type="string" name="client_contact_full_name" label="Case Contact Full Name" value={caseDetails.client_contact_full_name} onChange={contactFieldChange} />
								</div>
								<div>
									<Input
										type="string"
										name="client_contact_bcc"
										label='Case Contact "BCC" Address(es)'
										value={caseDetails.client_contact_bcc}
										onChange={contactFieldChange}
										customClassName="instruct__modal-content__client-ref-contact-inputs__input"
									/>
								</div>
								<div>
									<Input type="string" name="client_contact_email" label='Case Contact "To" Address' value={caseDetails.client_contact_email} onChange={contactFieldChange} />
								</div>
							</div>
						</div>
						<Checkbox
							multilineLabel={true}
							label="By clicking Instruct Selected Regions, I confirm that I am instructing this application in the regions indicated above.
                            I understand that my agent has not yet accepted responsibility for filing this case."
							name="confirm_instruct"
							value={confirmInstruct}
							onClick={onConfirmInstruct}
							error={errors.instruction_not_confirmed.message ? true : false}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

InstructModal.propTypes = {
	fetchRegions: PropTypes.func,
};

InstructModal.defaultProps = {
	fetchRegions: () => {},
};

export default InstructModal;
