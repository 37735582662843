import React, { useState, useEffect, useRef, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import AppTemplate from 'com/templates/ApplicationTemplate';
import { AppRoute } from 'com/util/AppRoute';
import AppLink from 'com/util/AppLink';
import Routes from 'data/routes';

import FirmDetailsMain from './FirmDetailsMain';
import FirmDetailsPoints from './FirmDetailsPoints';
import FirmDetailsMembers from './FirmDetailsMembers';
import FirmDetailsAccounts from './FirmDetailsAccounts';
import FirmDetailsExpertiseRegions from './FirmDetailsExpertiseRegions';
import FirmDetailsRates from './FirmDetailsRates';
import FirmDetailsEstimateRules from './FirmDetailsEstimateRules';
import FirmDetailsReciprocityRules from './FirmDetailsReciprocityRules';

import Button from 'com/ui/Button';
import DocumentUploader from 'com/widgets/DocumentUploader';
import SocialBox from 'com/widgets/SocialBox';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import ICON_DEFAULT_AVATAR from 'assets/images/icons/avatar_placeholder_invert.svg';
import ICON_SIDEBAR_SOCIAL from 'assets/images/icons/ico_sidebar_title_social.svg';
import ICON_SOCIAL_FACEBOOK from 'assets/images/icons/ico_social_facebook.svg';
import ICON_SOCIAL_LINKEDIN from 'assets/images/icons/ico_social_linkedin.svg';
import ICON_SOCIAL_TWITTER from 'assets/images/icons/ico_social_twitter.svg';

import Firms from 'services/rest/firms';
import FirmAccounts from 'services/rest/firm_accounts';
import useNotification from 'services/hooks/use_notification';
import EventLoggerEvents from 'services/rest/event_logger';
import Storage from 'services/rest/storage';
import { setIsUpdated } from 'redux/ducks/redirect';
import { formatString, random } from 'services/strings';

import './style.css';
import { OwnerContext, OwnerContextProvider } from 'util/ownerContext';
import { RoleBox } from 'com/util/RoleBox';

import { formatWebLink } from 'services/strings';
import { INTERNAL_ROLES, PAGE_TITLE, ASSOCIATE_TYPE, RATE_CONFIRMED_BY } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

const FirmDetails = (props) => {
	const ref = useRef(null);
	const { id } = useParams();
	const user = useSelector((state) => state.auth.user);
	const location = useLocation();

	const updatedTabsInit = {
		firm_details: false,
		expertise_regions: false,
		reciprocity: false,
		points: false,
		members: false,
		accounts: false,
		estimateRules: false,
		rates: false,
	};

	const erroredTabsInit = {
		firm_details: false,
		reciprocity: false,
		points: false,
		members: false,
		accounts: false,
	};

	const firmDataInit = {
		id: id,
		name: '',
		social_facebook: '',
		social_linkedin: '',
		social_twitter: '',
		regions: [],
		technologies: [],
		points: [],
		casesRegionsPoints: [],
		adjustedPoints: [],
		ratios: [],
		is_top_100: false,
		is_owed_case: false,
		is_centralized: false,
		family_id: '',
		firm_family_name: '',
		website: '',
		year_started: 0,
		tags: [],
		account_executive_id: '',
		is_vendor: false,
		is_non_recip_client: false,
		accounts_points: [],
		currency_id: '',
		members: [],
		address2: '',
		notes: '',
	};

	const mainDataErrorsInit = {
		name: '',
		// code: '',
		// website: '',
		// number_of_attorneys: '',
		// year_started: '',
		// address: '',
		// city: '',
		// state: '',
		// zipcode: '',
		country: '',
		type: '',
	};

	const [updatedTabs, setUpdatedTabs] = useState(updatedTabsInit);
	const [erroredTabs, setErroredTabs] = useState(erroredTabsInit);
	const [firmData, setFirmData] = useState(firmDataInit);
	const [mainDataErrors, setMainDataErrors] = useState(mainDataErrorsInit);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [logoUrl, setLogoUrl] = useState(null);
	const [confirmedBy, setConfirmedBy] = useState('');
	const sendNotification = useNotification();
	const dispatch = useDispatch();

	useTitle(`${firmData.name} ${PAGE_TITLE.FIRM_DETAILS}`);

	useEffect(() => {
		(async () => {
			try {
				setShowLoader(true);
				const fd = await Firms.GetOneByID(id);
				getLogoUrl(fd.image);
				setFirmData({
					...fd,
				});
				setShowLoader(false);
			} catch (err) {
				console.log(err);
				setShowLoader(false);
			}
		})();
	}, [id]);

	useEffect(() => {
		if (user.fid === id) {
			EventLoggerEvents.EventLogger({ event: 'User Visited Own Firm Profile', data: { user: user } });
		}
	}, []);

	const onMainDataChange = (e) => {
		switch (e.target.name) {
			case 'is_top_100':
			case 'is_owed_case':
			case 'is_centralized':
			case 'is_vendor':
			case 'is_non_recip_client':
				// let value = e.target.value === 'on';
				// console.log(e.target.value);
				setFirmData({
					...firmData,
					[e.target.name]: !firmData[e.target.name],
				});
				break;
			case 'family_id':
				setFirmData({
					...firmData,
					...e.target.value,
				});
				break;
			case 'number_of_attorneys':
				setFirmData({
					...firmData,
					[e.target.name]: Number(e.target.value),
				});
				break;
			default:
				setFirmData({
					...firmData,
					[e.target.name]: e.target.value,
				});
		}
		setUpdatedTabs({
			...updatedTabs,
			firm_details: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangeRegions = (region, action) => {
		switch (action) {
			case 'add':
				setFirmData({
					...firmData,
					regions: [
						...firmData.regions,
						{
							firm_id: firmData.id,
							region_code: region.code,
							region_id: region.id,
							region_name: region.name,
							is_archived: 0,
						},
					],
				});
				break;
			case 'archive':
				setFirmData({
					...firmData,
					regions: firmData.regions.map((r) => {
						if (r.region_id === region.id) {
							r.is_archived = r.is_archived ? 0 : 1;
						}
						return r;
					}),
				});
				break;
		}
		setUpdatedTabs({
			...updatedTabs,
			expertise_regions: true,
		});
		dispatch(setIsUpdated(true));
		// setUpdatedTabs({
		//     ...updatedTabs,
		//     rates: true,
		//     expertise_regions: true
		// });
		// console.log('regions update');
	};

	const onChangeRate = (field, region_id, service_id, client_id, value, rate_id) => {
		// descriptions for the conditions are written at the bottom of the parent function
		let confirmedByConditions = () => {
			if (user.fid === client_id && id !== user.fid) return RATE_CONFIRMED_BY.CLIENT;
			if (user.fid === id && id !== client_id) return RATE_CONFIRMED_BY.AGENT;
			return '';
		};

		if (!confirmedByConditions() && confirmedBy === '') {
			setConfirmedBy(RATE_CONFIRMED_BY.AZAMI);
		} else if (confirmedByConditions()) {
			setConfirmedBy(confirmedByConditions());
		}

		let defaultValues = {
			region_id: region_id,
			service_id: service_id,
			available: false,
			rate_professional: 0,
			rate_translation_type: 'WORD',
			rate_translation: 0,
			currency: 'USD',
			object_type: 'FIRM',
			override: false,
			rate_confirmed: false,
			rate_confirmed_by: confirmedByConditions(),
			client_id: client_id,
			associate_type: client_id === id ? ASSOCIATE_TYPE.IN : ASSOCIATE_TYPE.OON,
		};

		// check if any records exist
		let empty = firmData.rates === null;
		// check if specified record exists (if not empty)
		let exists = !empty && firmData.rates.filter((r) => r.service_id === service_id && r.region_id === region_id && r.client_id === client_id).length > 0;
		switch (field) {
			case 'available':
				if (empty || (!empty && !exists)) {
					setFirmData({
						...firmData,
						rates: [...(firmData.rates ? firmData.rates : []), { ...defaultValues, available: value === 'on', is_changed: true }],
					});
				}
				if (exists) {
					setFirmData({
						...firmData,
						rates: firmData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r.available = !r.available;
							}
							return r;
						}),
					});
				}
				break;
			case 'rate_confirmed':
				if (empty || (!empty && !exists)) {
					setFirmData({
						...firmData,
						rates: [...(firmData.rates ? firmData.rates : []), { ...defaultValues, rate_confirmed: value === 'on', is_changed: true }],
					});
				}
				if (exists) {
					setFirmData({
						...firmData,
						rates: firmData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r.rate_confirmed = !r.rate_confirmed;
							}
							return r;
						}),
					});
				}
				break;
			case 'currency':
				if (empty || (!empty && !exists)) {
					setFirmData({
						...firmData,
						rates: [...(firmData.rates ? firmData.rates : []), { ...defaultValues, currency: value, is_changed: true }],
					});
				}
				if (exists) {
					setFirmData({
						...firmData,
						rates: firmData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r.currency = value;
							}
							return r;
						}),
					});
				}
				break;
			case 'region_currency':
				if (!empty && value) {
					setFirmData({
						...firmData,
						rates: firmData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.client_id === client_id) {
								r.currency = value;
								r.is_changed = true;
							}
							return r;
						}),
					});
				}
				break;
			default:
				if (empty || (!empty && !exists)) {
					setFirmData({
						...firmData,
						rates: [...(firmData.rates ? firmData.rates : []), { ...defaultValues, [field]: value, is_changed: true }],
					});
				}
				if (exists) {
					setFirmData({
						...firmData,
						rates: firmData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r[field] = value;
							}
							return r;
						}),
					});
				}
				break;
		}

		// CONDITION 1
		/* If a client enters rates for an OON Associate,
		 * the confidence level should be automatically set
		 * to “Client-confirmed rates” (Medium). this can't be changed by the client
		 */
		if (user.fid === client_id && id !== user.fid && exists) {
			setFirmData({
				...firmData,
				rates: firmData.rates.map((r) => {
					if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
						r['rate_confirmed_by'] = RATE_CONFIRMED_BY.CLIENT;
					}
					return r;
				}),
			});
		}
		// CONDITION 2
		/* If firm is updating their own associate rates, the confidence level should be automatically set to “Agent-confirmed rates” (Highest confidence).
		 * An associate can also just view rates that a client submitted and change the confidence level to Agent-confirmed.
		 */
		if (user.fid === id && id !== client_id && exists) {
			setFirmData({
				...firmData,
				rates: firmData.rates.map((r) => {
					if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
						r['rate_confirmed_by'] = RATE_CONFIRMED_BY.AGENT;
					}
					return r;
				}),
			});
		}

		// CONDITION 3
		/* If an Azami employee is entering rates for an associate (either OON or IN rates),
		 * the Azami Employee is required to select Agent or Client for the confidence level
		 */

		setUpdatedTabs({
			...updatedTabs,
			rates: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangeAccounts = async (accounts) => {
		setShowSpinner(true);
		try {
			setFirmData({
				...firmData,
				accounts: accounts,
			});
			setUpdatedTabs({
				...updatedTabs,
				accounts: true,
			});
			dispatch(setIsUpdated(true));
			setShowSpinner(false);
		} catch (err) {
			setShowSpinner(false);
		}
	};

	const onAccountAddPoints = async (points) => {
		setShowSpinner(true);
		try {
			setFirmData({
				...firmData,
				accounts_points: [...(firmData.accounts_points || []), points],
			});
			setUpdatedTabs({
				...updatedTabs,
				accounts: true,
			});
			dispatch(setIsUpdated(true));
			setShowSpinner(false);
		} catch (err) {
			setShowSpinner(false);
		}
	};

	const validateMainFirmData = () => {
		let errs = {};
		let out = true;
		for (let fld in mainDataErrors) {
			let field = firmData[fld] !== undefined && firmData[fld] !== null && firmData[fld] !== '' ? firmData[fld] + '' : '';
			let e = '';
			if (field.trim().length === 0) {
				e = 'Required';
				out = false;
			}
			if (field < 0) {
				e = 'Enter a positive value';
				out = false;
			}
			errs = {
				...errs,
				[fld]: e,
			};
		}
		setMainDataErrors(errs);
		setErroredTabs({
			...erroredTabs,
			firm_details: true,
		});
		if (!out) {
			sendNotification({ type: 'error', title: 'Save failed. Fix errors and retry.' });
		}
		return out;
	};

	const saveFirmDetails = async () => {
		if (!validateMainFirmData()) return;
		setErroredTabs(erroredTabsInit);
		setShowSpinner(true);
		let ratesPayload = [];
		try {
			if (updatedTabs.firm_details) await Firms.UpdateByID(id, packFirmData());
			if (firmData.regions && firmData.regions.length >= 0 && updatedTabs.expertise_regions) await Firms.UpdateRegions(id, firmData.regions);
			if (firmData.rates && firmData.rates.length > 0 && updatedTabs.rates) {
				ratesPayload = firmData.rates.filter((r) => r.is_changed === true);
				await Firms.UpdateRates(id, ratesPayload);

				const payload = { firm_id: id, rates: ratesPayload };
				Firms.NotifyRatesChanged(payload);

				switch (confirmedBy) {
					case RATE_CONFIRMED_BY.CLIENT:
						EventLoggerEvents.EventLogger({ event: 'Client updates OON Assoc rates', data: { user: user } });
						break;
					case RATE_CONFIRMED_BY.AGENT:
						EventLoggerEvents.EventLogger({ event: 'OON Assoc updates their own rates', data: { user: user } });
						break;
					case RATE_CONFIRMED_BY.AZAMI:
						EventLoggerEvents.EventLogger({ event: 'Azami updates rates', data: { user: user } });
						break;
				}
			}

			if (firmData.accounts && firmData.accounts.length > 0 && updatedTabs.accounts) {
				for (let a of firmData.accounts) {
					if (!a.action) continue;

					a.user_ids = a.users.map((u) => u.id);
					switch (a.action) {
						case 'created':
							await FirmAccounts.Create(a.firm_id, a);
							break;
						case 'edited':
							await FirmAccounts.UpdateByID(a.firm_id, a.id, a);
							break;
						case 'deleted':
							await FirmAccounts.DeleteByID(a.firm_id, a.id);
							break;
					}
				}
				setFirmData((prevState) => {
					return {
						...prevState,
						accounts: prevState.accounts.filter((ac) => ac.action != 'deleted').map((ac) => ({ ...ac, action: null })),
					};
				});
			}
			if (firmData.accounts_points && firmData.accounts_points.length > 0) {
				for (let ap of firmData.accounts_points) {
					await FirmAccounts.AddPoints(ap.id, ap);
				}
				setFirmData((prevState) => {
					return {
						...prevState,
						accounts_points: [],
					};
				});
			}

			Object.keys(updatedTabs).map((u) => {
				if (updatedTabs[u] !== true) {
					return u;
				}
				u = formatString(u);
				EventLoggerEvents.EventLogger({ event: `Firm ${u} Tab Updated`, data: { firm_updated_id: firmData.id, firm_updated_name: firmData.name, user: user } });
			});
			setUpdatedTabs(updatedTabsInit);
			dispatch(setIsUpdated(false));

			// new rates needs to be refetched
			if (ratesPayload.length > 0) {
				let rates = await Firms.GetRatesByFirmID(id);
				setFirmData((prevState) => {
					return {
						...prevState,
						rates: rates,
					};
				});
			}
			sendNotification({ type: 'success', title: 'Successfully saved firm details data' });
			setShowSpinner(false);
		} catch (err) {
			console.log(err);
			setShowSpinner(false);
			sendNotification({ type: 'error', title: 'Unsuccessfully saved firm details data' });
		}
	};

	// The state contains additional fields that are not part of the firms's update payload. We need to filter them out.
	const packFirmData = () => {
		let allowedProps = [
			'_deleted',
			'xid',
			'code',
			'name',
			'description',
			'number_of_attorneys',
			'website',
			'image',
			'email',
			'address',
			'address2',
			'phone1',
			'phone2',
			'city',
			'country',
			'state',
			'zipcode',
			'currency_id',
			'pricing_level_id',
			'pricing_level_translation_id',
			'pricing_level_validations_id',
			'pricing_level_validations_translation_id',
			'is_top_100',
			'is_active',
			'is_confirmed',
			'is_owed_case',
			'type',
			'is_centralized',
			'family_id',
			'year_started',
			'tags',
			'account_executive_id',
			'is_vendor',
			'is_non_recip_client',
			'default_account_id',
			'notes',
		];
		const filtered = allowedProps.reduce((obj, key) => {
			obj[key] = firmData[key];
			return obj;
		}, {});
		return filtered;
	};

	const triggerFileselector = (e) => {
		ref.current.click();
	};

	const uploadImage = async (e) => {
		try {
			if (e.target.files.length === 0) return;
			const result = await Storage.UploadImage('logos', 'image', e.target.files[0]);
			if (result.filename) {
				// only firm data is updated so rates are set to [] in payload because it can get too large
				await Firms.UpdateByID(firmData.id, { ...firmData, rates: [], image: result.filename });
				setFirmData({
					...firmData,
					image: result.filename,
				});
				let string = random(20);
				setLogoUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${result.filename}?${string}`);
				sendNotification({ type: 'success', title: 'Logo saved' });
			} else {
				sendNotification({ type: 'error', title: 'Logo not saved' });
			}
		} catch (err) {
			sendNotification({ type: 'error', title: 'Logo not saved' });
		}
	};

	const getLogoUrl = async (image) => {
		if (image) {
			let string = random(20);
			setLogoUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${image}?${string}`);
		} else {
			setLogoUrl(ICON_DEFAULT_AVATAR);
		}
	};

	return (
		<AppTemplate title="Firm Details" headerIcon="firm_management" collapsibleSidebar={false}>
			<AppTemplate.Sidebar>
				<OwnerContextProvider firmId={firmData.id}>
					<div className="firm-sidebar">
						<div className="firm-sidebar__image-container">
							<picture>
								<source srcSet="" />
								<img className="firm-sidebar__image-container__avatar" src={logoUrl} alt="" />
							</picture>
							<RoleBox roles={['$firm']}>
								<button className="firm-sidebar__image-container__btn-change-avatar" onClick={triggerFileselector}>
									<input id="logo-upload" name="image" type="file" hidden onChange={uploadImage} ref={ref} />
								</button>
							</RoleBox>
						</div>
						<div className="firm-sidebar__firm-info">
							<span className="firm-sidebar__firm-info__name">{firmData.name}</span>
							<span className="firm-sidebar__firm-info__positon-company">{firmData.city}</span>
							{firmData.website != '' ?
								<a className="firm-sidebar__firm-info__company-website" href={formatWebLink(firmData.website)} target="_blank">
									{firmData.website}
								</a>
							:	null}
						</div>
						{/* THE CODE FROM LINE 655 TO LINE 678 IS COMMENTED BECAUSE FOR NOW IT'S NOT USED, BUT MAY BE IN THE FUTURE  */}
						{/* <RoleBox roles={['admin', 'sales', 'sales_manager']}>
                            <div className="firm-sidebar__section">
                                <div className="firm-sidebar__section__title">
                                    <img src={ICON_SIDEBAR_SOCIAL} />
                                    Social media
                                </div>
                                <div className="firm-sidebar__section__body">
                                    <ul className="firm-sidebar__section__body__social-links">
                                        <li>
                                            <SocialBox icon={ICON_SOCIAL_FACEBOOK} title="Facebook" titleClassName="facebook" name="social_facebook" value={firmData.social_facebook} onChange={onMainDataChange} />
                                        </li>
                                        <li>
                                            <SocialBox icon={ICON_SOCIAL_LINKEDIN} title="Linkedin" titleClassName="linkedin" name="social_linkedin" value={firmData.social_linkedin} onChange={onMainDataChange} />
                                        </li>
                                        <li>
                                            <SocialBox icon={ICON_SOCIAL_TWITTER} title="Twitter" titleClassName="twitter" name="social_twitter" value={firmData.social_twitter} onChange={onMainDataChange} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="firm-sidebar__uploader">
                                <DocumentUploader title="Upload Documents" />
                            </div>
                        </RoleBox> */}
					</div>
				</OwnerContextProvider>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<OwnerContextProvider firmId={firmData.id}>
					<div className="main-screen-section white full-height no-padding firm-details-content">
						<LoaderOverlay showLoader={showLoader} />
						<header className="firm-details-content__header">
							<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager', '$clientOfFirm', '$agentOfFirm']}>
								<nav>
									<AppLink type="nav" to={`/firm-management/${id}`} activeClassName="active" exact={true} trackClick={true} event="Firm Details Tab Opened">
										{updatedTabs.firm_details ?
											<span className="firm-details-content__header__update-indicator"></span>
										:	null}
										{erroredTabs.firm_details ?
											<span className="firm-details-content__header__error-indicator"></span>
										:	null}
										Firm details
									</AppLink>
									<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager', '$clientOfFirm']}>
										<AppLink type="nav" to={`/firm-management/${id}/expertise-regions`} activeClassName="active" trackClick={true} event="Firm Expertise &amp; regions Tab Opened">
											{updatedTabs.expertise_regions ?
												<span className="firm-details-content__header__update-indicator"></span>
											:	null}
											Expertise &amp; regions
										</AppLink>
									</RoleBox>
									<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager', '$clientOfFirm']}>
										<AppLink type="nav" to={`/firm-management/${id}/rates`} activeClassName="active" trackClick={true} event="Firm Rates Tab Opened">
											{updatedTabs.reciprocity ?
												<span className="firm-details-content__header__update-indicator"></span>
											:	null}
											Rates
										</AppLink>
									</RoleBox>
									<RoleBox roles={['admin', 'sales', 'sales_manager']}>
										<AppLink type="nav" to={`/firm-management/${id}/points`} activeClassName="active" trackClick={true} event="Firm Points Tab Opened">
											{updatedTabs.points ?
												<span className="firm-details-content__header__update-indicator"></span>
											:	null}
											Points
										</AppLink>
									</RoleBox>
									<RoleBox roles={['admin', 'sales', 'sales_manager', '$firm']}>
										<AppLink type="nav" to={`/firm-management/${id}/members`} activeClassName="active" trackClick={true} event="Firm Members Tab Opened">
											{updatedTabs.members ?
												<span className="firm-details-content__header__update-indicator"></span>
											:	null}
											Members
										</AppLink>
									</RoleBox>
									<RoleBox roles={INTERNAL_ROLES}>
										<AppLink type="nav" to={`/firm-management/${id}/accounts`} activeClassName="active" trackClick={true} event="Firm Recip. Accounts Tab Opened">
											{updatedTabs.acounts ?
												<span className="firm-details-content__header__update-indicator"></span>
											:	null}
											Recip. Accounts
										</AppLink>
										<AppLink type="nav" to={`/firm-management/${id}/reciprocity-rules`} activeClassName="active" trackClick={true} event="Firm Recip. Rules Tab Opened">
											Recip. Rules
										</AppLink>
										<AppLink type="nav" to={`/firm-management/${id}/estimate-rules`} activeClassName="active" trackClick={true} event="Firm Estimate Rules Tab Opened">
											{updatedTabs.estimateRules ?
												<span className="firm-details-content__header__update-indicator"></span>
											:	null}
											Estimate Rules
										</AppLink>
									</RoleBox>
								</nav>
							</RoleBox>
						</header>
						<div className="firm-details-content__body">
							<AppRoute route={Routes.FIRM_DETAILS_MAIN} exact onChange={onMainDataChange} data={firmData} errors={mainDataErrors} />
							<AppRoute
								route={Routes.FIRM_DETAILS_EXPERTISE_REGIONS}
								exact
								regions={firmData.regions}
								technologies={firmData.technologies}
								onChangeRegions={onChangeRegions}
								firmName={firmData.name}
							/>
							<AppRoute route={Routes.FIRM_DETAILS_RATES} onChangeRate={onChangeRate} firmData={firmData} exact />
							<AppRoute route={Routes.FIRM_DETAILS_POINTS} />
							<AppRoute route={Routes.FIRM_DETAILS_MEMBERS} exact data={firmData} />
							<AppRoute route={Routes.FIRM_DETAILS_ACCOUNTS} data={firmData} onChangeAccounts={onChangeAccounts} onAccountAddPoints={onAccountAddPoints} />
							<AppRoute route={Routes.FIRM_DETAILS_ESTIMATE_RULES} exact data={firmData} sendNotification={sendNotification} />
							<AppRoute route={Routes.FIRM_DETAILS_RECIPROCITY_RULES} firmAccounts={firmData.accounts} />
						</div>
						<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager', '$clientOfFirm', '$agentOfFirm']}>
							<footer className="firm-details-content__footer">
								<Button onClick={saveFirmDetails} showSpinner={showSpinner}>
									Save
								</Button>
							</footer>
						</RoleBox>
					</div>
				</OwnerContextProvider>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export { FirmDetailsMain, FirmDetailsPoints, FirmDetailsMembers, FirmDetailsAccounts, FirmDetailsExpertiseRegions, FirmDetailsRates, FirmDetailsEstimateRules, FirmDetailsReciprocityRules };

export default FirmDetails;
