import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';

import DataTable from 'com/widgets/DataTable';
import PricePoints from 'com/widgets/PricePoints';
import StarsRating from 'com/widgets/StarsRating';

import { roundDecimals } from 'services/strings';

import { QB_NUM_OF_ASSOC_DISPLAYED } from 'data/constants';

import './style.css';

const QuoteAllAssociatesModal = (props) => {
	const [search, setSearch] = useState('');
	const [displayAssociates, setDisplayAssociates] = useState(props.displayedAssociates);

	useEffect(() => {
		return () => {
			// clean assocaite id that is selected for swap
			props.setSwapAssociateId('');
		};
	}, []);

	const inputChange = (e) => {
		setSearch(e.target.value);
		let str = e.target.value.toLowerCase().trim();
		let matches = props.displayedAssociates.filter((element) => {
			let firm_name = element.firm_name.toLowerCase().trim();
			if (firm_name.includes(str)) {
				return true;
			}
		});
		setDisplayAssociates(matches);
	};

	const selectAssociate = (e) => {
		let region_id = e.target.closest('tr').dataset.region;
		let regionData = props.data.find((e) => e.region_id === region_id);
		let associate = e.target.closest('tr').dataset.associate;
		// Perform swap of associates only if the modal is used from quote playground
		if (props.fromPlayGround) {
			if (
				regionData.associates
					.filter((a) => a.display === true)
					.slice(0, QB_NUM_OF_ASSOC_DISPLAYED)
					.find((a) => a.firm_id === associate)
			) {
				props.selectAssociate(region_id, associate, props.associatesData);
				if (!props.selectedRegionsForInstruct.includes(region_id)) {
					props.setSelectedRegionsForInstruct([...props.selectedRegionsForInstruct, region_id]);
				}

				return props.closeHandler();
			}

			if (!props.swapAssociateId) {
				let selectedAssociate = regionData.associates.find((a) => a.firm_id === associate);
				props.setData((prevState) => {
					let out = prevState.map((d) => {
						if (d.region_id === region_id) {
							d.associates = [
								...d.associates.map((a) => {
									if (a.firm_id === selectedAssociate.firm_id) a.display = true;
									return a;
								}),
							];
						}
						return d;
					});
					return out;
				});
			} else {
				let swapAssociateIndex = regionData.associates.findIndex((a) => a.firm_id === props.swapAssociateId);
				let selectedAssociateIndex = regionData.associates.findIndex((a) => a.firm_id === associate);

				props.setData((prevState) => {
					let out = prevState.map((d) => {
						if (d.region_id === region_id) {
							let swapAssociate = d.associates[swapAssociateIndex];
							let selectedAssociate = d.associates[selectedAssociateIndex];
							d.associates[selectedAssociateIndex] = swapAssociate;
							d.associates[swapAssociateIndex] = selectedAssociate;
							d.associates = [
								...d.associates.map((a) => {
									if (a.firm_id === selectedAssociate.firm_id) a.display = true;
									if (a.firm_id === swapAssociate.firm_id) a.display = false;
									return a;
								}),
							];
						}
						return d;
					});
					return out;
				});
			}
		}

		props.selectAssociate(region_id, associate, props.associatesData);
		if (props.fromPlayGround && !props.selectedRegionsForInstruct.includes(region_id)) {
			props.setSelectedRegionsForInstruct([...props.selectedRegionsForInstruct, region_id]);
		}
		props.closeHandler();
	};

	const filterTableHeaders = (tableHeaders) => {
		//clone to avoid mutation
		let tmp_h = tableHeaders.slice();
		// swap region and associate columns
		var b = tmp_h[1];
		tmp_h[1] = tmp_h[3];
		tmp_h[3] = b;
		// remove columns that are not on the modal
		return tmp_h.filter((th) => th.field !== 'reg' && th.field !== 'language' && th.field !== 'reciprocity');
	};

	return (
		<Modal title="Manual Selection" footerActions={props.footerActions} closeHandler={props.closeHandler}>
			<div className="all-associate-modal-content">
				<div>Search Firm Name</div>
				<Input name="search" placeholder="Enter Firm Name" value={search} onChange={inputChange} />
				<div className="all-associate-modal-content-table-container">
					<DataTable fixedHeader={false} columns={filterTableHeaders(props.tableHeaders)} onColumnSort={props.columnSort}>
						{displayAssociates.length > 0 ?
							displayAssociates.map((a, i) => {
								return (
									<tr key={a.region_code + i} data-associate={a.firm_id} data-region={a.region_id} class={`${a.is_active ? '' : 'inactive'} ${a.is_favorite ? 'favorite' : ''}`}>
										<DataTable.PersonCell avatar="">{a.firm_name}</DataTable.PersonCell>
										<DataTable.CountryCell code={a.region_code || ''}>{a.region_name}</DataTable.CountryCell>
										<DataTable.Cell customClassName="all-associate-modal-content-table-price-cell">{`${props.currencySymbol}${a.rate_professional}`}</DataTable.Cell>
										<DataTable.Cell customClassName="all-associate-modal-content-table-price-cell">{`${props.currencySymbol}${a.rate_translation.toFixed(2)}`}</DataTable.Cell>
										<DataTable.Cell customClassName="all-associate-modal-content-table-price-cell">{`${props.currencySymbol}${a.rate_official}`}</DataTable.Cell>
										<DataTable.Cell customClassName="all-associate-modal-content-table-price-cell">
											{`${props.currencySymbol}${(a.rate_official + a.rate_translation + a.rate_professional).toFixed(2)}`}
										</DataTable.Cell>
										<DataTable.Cell customClassName="all-associate-modal-content-table-price-cell">
											<div className="associate-row-service" key={i}>
												<StarsRating rating={a.firm_id !== '0' ? a.rating : 0} />
											</div>
										</DataTable.Cell>
										<DataTable.Cell customClassName="all-associate-modal-content-table-price-cell">
											<div className="associate-row-pricing" key={i}>
												<PricePoints length={5} value={a.firm_id !== '0' ? roundDecimals(a.points?.value, 2) : 0} threshold={a.firm_id !== '0' ? a.points?.threshold : 0} />
											</div>
										</DataTable.Cell>
										<DataTable.Cell>
											<Button onClick={selectAssociate}>Select</Button>
										</DataTable.Cell>
									</tr>
								);
							})
						:	null}
					</DataTable>
				</div>
			</div>
		</Modal>
	);
};

QuoteAllAssociatesModal.propTypes = {
	displayedAssociates: PropTypes.array,
	swapAssociateId: PropTypes.string,
	setSwapAssociateId: PropTypes.func,
	data: PropTypes.array,
	setData: PropTypes.func,
	fromPlayGround: PropTypes.bool,
	selectAssociate: PropTypes.func,
	associatesData: PropTypes.object,
	selectedRegionsForInstruct: PropTypes.array,
	setRegionsForInstruct: PropTypes.func,
	closeHandler: PropTypes.func,
	selectAssociate: PropTypes.func,
};

QuoteAllAssociatesModal.defaultProps = {
	displayedAssociates: [],
	swapAssociateId: '',
	setSwapAssociateId: () => {},
	data: [],
	setData: () => {},
	fromPlayGround: false,
	selectAssociate: () => {},
	associatesData: {},
	selectedRegionsForInstruct: [],
	setRegionsForInstruct: () => {},
	closeHandler: () => {},
	selectAssociate: () => {},
};

export default QuoteAllAssociatesModal;
