import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setQuoteData, setQuoteDataFromObject } from 'redux/ducks/quote';

import Checkbox from 'com/ui/Checkbox';
import Input from 'com/ui/Input';
import Textarea from 'com/ui/Textarea';
import Switch from 'com/ui/Switch';
import Loader from 'com/ui/Loader';
import { RoleBox } from 'com/util/RoleBox';
import AttachmentsTable from 'com/widgets/AttachmentsTable';

import Users from 'services/rest/users';
import Estimates from 'services/rest/estimates';

import { formatEmailsStringToJSON } from 'services/strings';

import { QUOTE_LIST_FIELDS, INTERNAL_ROLES, EXTERNAL_ROLES } from 'data/constants';
import { getFlag } from 'data/flags';

import './style.css';

const AdditionalInformationBase = (props) => {
	const [optionalRules, setOptionalRules] = useState([]);
	const [discountRegions, setDiscountRegions] = useState({});

	useEffect(() => {
		(async () => {
			try {
				if (props.quote.service_id && props.quote.client_firm_id && props.quote.regions.length > 0 && props.quote.client_id) {
					getOptionalRules();
				}
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);

	const optionalRuleChange = (e) => {
		let applied_optional_rules = [];
		if (!e.target.checked) {
			applied_optional_rules = [...props.quote.applied_optional_rules.filter((or) => or !== e.target.value)];
		} else {
			applied_optional_rules = [...props.quote.applied_optional_rules, e.target.value];
		}
		props.setQuoteData({ name: e.target.name, value: applied_optional_rules });
	};

	const discountRegionChange = (e) => {
		let [name, rid, field] = e.target.name.split('__');
		let ignored_discount_regions = {};
		if (e.target.checked) {
			ignored_discount_regions = {
				...(props.quote.ignored_discount_regions || {}),
				[field]: props.quote.ignored_discount_regions && props.quote.ignored_discount_regions[field] ? [...props.quote.ignored_discount_regions[field], rid] : [rid],
			};
		} else {
			ignored_discount_regions = {
				...props.quote.ignored_discount_regions,
				[field]: [...props.quote.ignored_discount_regions[field].filter((dr) => dr !== rid)],
			};
		}
		props.setQuoteData({ name: 'ignored_discount_regions', value: ignored_discount_regions });
	};

	const checkboxChange = (e) => {
		props.setQuoteData({ name: e.target.name, value: !props.quote[e.target.name] });
	};

	const fieldChange = (e) => {
		props.setQuoteData({ name: e.target.name, value: e.target.value });
	};

	useEffect(async () => {
		if (
			(props.instructWithoutQuote && props.quote.client_id && !props.quote.client_contact_greeting) ||
			!props.quote.client_contact_email_for_quotes ||
			!props.quote.client_contact_full_name ||
			!props.quote.client_contact_email
		) {
			if (props.quote.client_id) {
				let user = await Users.GetOne(props.quote.client_id);
				if (user) {
					props.setQuoteDataFromObject({
						client_contact_greeting: user.first_name || '',
						client_contact_email_for_quotes: formatEmailsStringToJSON(user.email_for_quotes || ''),
						client_contact_full_name: `${user.first_name} ${user.last_name}`,
						client_contact_email: user.email || '',
					});
				}
			}
		}
	}, [props.quote.client_id]);

	const getOptionalRules = async () => {
		let res = await Estimates.CreatePreCalculation({ quote: props.quote });
		if (res.optional_rules) {
			Object.keys(res.optional_rules).map((key) => {
				if (res.optional_rules[key]?.length) {
					for (let i = 0; i < res.optional_rules[key].length; i++) {
						if (!optionalRules.find((r) => r.id === res.optional_rules[key][i].id)) {
							setOptionalRules([...optionalRules, res.optional_rules[key][i]]);
						}
					}
				}
			});

			let appliedOptionalRules = [];
			Object.keys(res.optional_rules).map((key) => {
				return res.optional_rules[key].map((rule) => {
					if (rule.optional_rule_preselected) {
						appliedOptionalRules.push(rule.id);
					}
				});
			});

			props.setQuoteData({ name: 'applied_optional_rules', value: appliedOptionalRules });
		}

		// Set Discount Rules Regions
		if (res.discount_rules) {
			let discountRegions = {};
			Object.keys(res.discount_rules).forEach((rid) => {
				let uniqueFields = [...new Set(res.discount_rules[rid].map((r) => r.calculation_field))];
				uniqueFields.forEach((f) => {
					if (!discountRegions[f]) discountRegions[f] = [];
					discountRegions[f].push(rid);
				});
			});
			setDiscountRegions(discountRegions);
		}
	};

	return (
		<div className="quote-details__section-container">
			{/* <h4 className={`quote-details__subtitle ${props.errors.agree_to_terms ? 'section-title-error' : ''}`}>5. Additional Info &amp; Attachments</h4> */}
			<div className="quote-details__info-attachments__inputs">
				<div className="new-quote-wizard__content-attachements-top">
					<div className="new-quote-wizard__content-attachements-left right-separator">
						<RoleBox roles={['saas_user']}>
							<p>An automatically generated quote will not include adjustments for ATTACHMENTS or ADDITIONAL DETAILS.</p>
						</RoleBox>
						<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
							<p>
								An automatically generated quote will not include adjustments for ATTACHMENTS or ADDITIONAL DETAILS. We will review your attachments and additional details and send you
								an updated quote, if necessary.
							</p>
						</RoleBox>
						{optionalRules.length > 0 ?
							<>
								<h4 className="quote-details__subtitle">Additional Options</h4>
								{optionalRules.map((rule) => {
									return (
										<Checkbox
											key={rule.id}
											label={rule.optional_rule_text}
											value={rule.id}
											checked={props.quote.applied_optional_rules.includes(rule.id)}
											onClick={optionalRuleChange}
											name={'applied_optional_rules'}
										/>
									);
								})}
							</>
						:	null}
						<RoleBox roles={INTERNAL_ROLES}>
							{discountRegions[QUOTE_LIST_FIELDS.ENTITY_SIZE] ?
								<>
									<h4 className="quote-details__subtitle">Ignore Small/Individual Entity Size</h4>
									{discountRegions[QUOTE_LIST_FIELDS.ENTITY_SIZE].map((rid) => {
										let regionData = props.regions.find((r) => r.id === rid);
										return (
											<Switch
												key={rid}
												customClassName="discount-rule-switch"
												name={`discount_region__${rid}__${QUOTE_LIST_FIELDS.ENTITY_SIZE}`}
												label={regionData.name}
												img={getFlag(regionData.code)}
												theme="blurple"
												onChange={discountRegionChange}
												value={props.quote.ignored_discount_regions ? props.quote.ignored_discount_regions[QUOTE_LIST_FIELDS.ENTITY_SIZE]?.includes(rid) : false}
											/>
										);
									})}
								</>
							:	null}
						</RoleBox>
						<RoleBox roles={['user', 'member', 'member_assistant', 'candidate', 'admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager']}>
							<Textarea
								label="Additional details that affect the application"
								customClassName="quote-details__info-attachments__inputs__textarea"
								name="additional_info"
								value={props.quote.additional_info}
								onChange={fieldChange}
							/>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<Textarea
								label="Notes by the estimation team"
								customClassName="quote-details__info-attachments__inputs__textarea"
								name="internal_quote_notes"
								value={props.quote.internal_quote_notes}
								onChange={fieldChange}
							/>
							<Textarea
								label="Notes that appear on the quote pdf"
								customClassName="quote-details__info-attachments__inputs__textarea"
								name="custom_pdf_notes"
								value={props.quote.custom_pdf_notes}
								onChange={fieldChange}
							/>
						</RoleBox>
						{props.instructWithoutQuote ?
							<div className="new-quote-wizard__content-attachements-left-client-contact">
								<div>
									<Input type="string" name="client_contact_greeting" label="Case Contact Greeting Name" value={props.quote.client_contact_greeting} onChange={fieldChange} />
								</div>
								<div>
									<Input
										type="string"
										name="client_contact_email_for_quotes"
										label="Case Contact CC Address(es)"
										value={props.quote.client_contact_email_for_quotes}
										onChange={fieldChange}
										customClassName="new-quote-wizard__content-attachements-left-client-contact__input"
									/>
								</div>
								<div>
									<Input type="string" name="client_contact_full_name" label="Case Contact Full Name" value={props.quote.client_contact_full_name} onChange={fieldChange} />
								</div>
								<div>
									<Input
										type="string"
										name="client_contact_bcc"
										label="Case Contact BCC Address(es)"
										value={props.quote.client_contact_bcc}
										onChange={fieldChange}
										customClassName="new-quote-wizard__content-attachements-left-client-contact__input"
									/>
								</div>
								<div>
									<Input type="string" name="client_contact_email" label="Case Contact To Address" value={props.quote.client_contact_email} onChange={fieldChange} />
								</div>
							</div>
						:	null}
					</div>
					{props.uploadTable ?
						<div className="new-quote-wizard__content-attachements-right">
							<AttachmentsTable
								regions={props.regions.filter((r) => props.quote.regions.includes(r.id))}
								documents={props.documents}
								setDocuments={props.setDocuments}
								spinner={props.spinner}
								setSpinner={props.setSpinner}
								objectTypeInit={props.objectTypeInit}
								allowDelete={true}
								checkFileType={true}
							/>
						</div>
					:	null}
				</div>
				<Checkbox
					multilineLabel={true}
					labelClassName="quote-details__info-attachments__inputs__checkbox"
					name="agree_to_terms"
					value={!!props.quote.agree_to_terms}
					onClick={checkboxChange}
					checked={props.quote.agree_to_terms === 1 || props.quote.agree_to_terms === true ? true : false}
					label={
						!props.instructWithoutQuote ?
							'By clicking Request Quote, I understand that the quote I receive will be based on the data I entered or upon data that was automatically populated that I have reviewed. I understand that any data contained in ATTACHMENTS or provided in ADDITIONAL DETAILS that have a bearing on costs are not a part of the estimate provided at this time. Final costs will be based on actual application details.'
						:	'By clicking Instruct Case, I confirm that I am instructing this application in all the regions previously selected. I understand that my agent has not yet accepted responsibility for filing this case. I understand that any data contained in ATTACHMENTS or provided in ADDITIONAL DETAILS that have a bearing on costs are not a part of the quote on the Quotes list. Final costs will be based on actual application details.'
					}
					error={props.errors.agree_to_terms}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		quote: state.quote,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setQuoteData: (data) => {
			dispatch(setQuoteData(data));
		},
		setQuoteDataFromObject: (data) => {
			dispatch(setQuoteDataFromObject(data));
		},
	};
};

const AdditionalInformation = connect(mapStateToProps, mapDispatchToProps)(AdditionalInformationBase);

export default AdditionalInformation;
