import { useState, useRef, useCallback } from 'react';
import { useMapEvents, useMap, Marker } from 'react-leaflet';
import { divIcon } from 'leaflet';

const Markers = (props) => {
	const mapInstance = useMap();
	const mapRef = useRef(null);
	mapRef.current = mapInstance;

	const [mapZoom, setMapZoom] = useState(mapInstance.getZoom());
	useMapEvents({
		zoomend: () => {
			setMapZoom(mapInstance.getZoom());
		},
	});

	const onMarkerClick = useCallback((regionId) => {
		return props.setSelectedRegion(regionId);
	}, []);

	return props.regions.map((r) => <CustomMarker key={r.region_id} position={r.center} onClick={onMarkerClick} casesInRegion={r.agents_in_reg} regionId={r.region_id} />);
};

const CustomMarker = ({ position, key, onClick, casesInRegion, regionId }) => {
	const icon = divIcon({
		className: 'dashboard-map__map-marker',
		iconSize: [25, 25],
		iconAnchor: [10, 10],
		html: `<div>${casesInRegion}</div>`,
	});

	return <Marker position={position} icon={icon} key={key} eventHandlers={{ click: () => onClick(regionId) }} />;
};

export default Markers;
