import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { getDomain } from 'services/strings';
// import { Redirect, Route } from 'react-router-dom';

import { AppZones, Private, Public, Shared } from './com/util/AppZones';
import { AppRoute } from './com/util/AppRoute';
import Routes from './data/routes';
import { DOMAINS, ROLE_TYPES } from 'data/constants';
import Auth from 'services/rest/auth';
import JWT from 'services/jwt';
import { setLoggedIn, setUserData } from 'redux/ducks/auth';
import { setBrandingAzami, setBrandingIpeer } from 'redux/ducks/branding';

import './assets/styles/global.css';

const App = () => {
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const location = useLocation();
	const domain = getDomain();

	useEffect(() => {
		let token = localStorage.getItem('token');
		if (token) {
			Auth.ValidateToken()
				.then((res) => {
					if (res === token) {
						const user = JWT.parseJWT(res);
						if (user.roleType === ROLE_TYPES.EXTERNAL) {
							Auth.RefreshToken().then((refreshedToken) => {
								localStorage.setItem('token', refreshedToken);
							});
						}
						// setUserData(user);
						dispatch(setUserData(user));
						// setLoggedIn(true);
						dispatch(setLoggedIn(true));
					}
					setShow(true);
				})
				.catch((err) => {
					console.log(err);
					setShow(true);
				});
		} else {
			setShow(true);
		}
	}, [setUserData, setLoggedIn]);

	useEffect(() => {
		if (auth.loggedIn) {
			Userpilot.identify(auth.user.uid, {
				name: auth.user.name,
				email: auth.user.email,
				role: auth.user.role,
			});
			if (auth.user.role.includes('saas_user')) {
				dispatch(setBrandingIpeer());
			} else {
				dispatch(setBrandingAzami());
			}
		} else if (!auth.loggedIn) {
			Userpilot.anonymous();
		}
	}, [auth.loggedIn]);

	useEffect(() => {
		Userpilot.reload();
	}, [location]);

	useEffect(() => {
		const isIpeer = DOMAINS.IPEER.includes(btoa(domain));
		const getElement = document.getElementById('favicon');
		isIpeer ? (getElement.href = '/favicon_ipeer.ico') : (getElement.href = '/favicon_azami.ico');
	}, [domain]);

	return (
		show && (
			<AppZones>
				<Public>
					<AppRoute route={Routes.ROOT} exact />
					<AppRoute route={Routes.REGISTER} exact />
					<AppRoute route={Routes.REGISTER_THANK_YOU} />
					<AppRoute route={Routes.FORGOT_PASSWORD} exact />
					<AppRoute route={Routes.FORGOT_PASSWORD_MESSAGE} />
					<AppRoute route={Routes.CHANGE_PASSWORD_MESSAGE} />
					<AppRoute route={Routes.CHANGE_PASSWORD} />
					<AppRoute route={Routes.CONFIRM_ACCOUNT} />
					<AppRoute route={Routes.LEGAL_INFO} />
					<AppRoute route={Routes.WILDCARD} />
				</Public>
				<Private>
					<AppRoute route={Routes.DASHBOARD} exact />
					<AppRoute route={Routes.REPORTS} exact />
					<AppRoute route={Routes.REPORTS_CLIENT} />
					<AppRoute route={Routes.REPORTS_REGIONAL} />
					<AppRoute route={Routes.REPORTS_CASE} />
					<AppRoute route={Routes.REPORTS_TOP_10} />
					<AppRoute route={Routes.REPORTS_OWED_ASSOCIATES} />
					<AppRoute route={Routes.ASSOCIATE_ASSIGNMENT} />
					<AppRoute route={Routes.USER_MANAGEMENT_LIST} exact />
					<AppRoute route={Routes.USER_ADD} />
					<AppRoute route={Routes.USER_DETAILS} />
					<AppRoute route={Routes.FIRM_MANAGEMENT_LIST} exact />
					<AppRoute route={Routes.FIRM_ADD} exact />
					<AppRoute route={Routes.FIRM_DETAILS} />
					<AppRoute route={Routes.AGENTS} />
					<AppRoute route={Routes.TOOLS} exact />
					<AppRoute route={Routes.ASSIGNMENT_OVERRIDE_REQUESTS} />
					<AppRoute route={Routes.TRANSLATION_REQUESTS} />
					<AppRoute route={Routes.DUPLICATE_MANAGEMENT} />
					{/* COMMENTING OUT BECAUSE IT IS NOT READY FOR PRODUCTION */}
					{/* <AppRoute route={Routes.CLIENT_ONBOARDING} /> */}
					<AppRoute route={Routes.SYSTEM_SETTINGS} />
					<AppRoute route={Routes.QUOTES_ADD} exact />
					<AppRoute route={Routes.QUOTES_FINISH_DRAFT_QUOTE} exact />
					<AppRoute route={Routes.QUOTES_FINISH_DRAFT_INSTRUCT_WITHOUT_PRE_EXISTING} exact />
					<AppRoute route={Routes.QUOTES_INSTRUCT_WITHOUT_PRE_EXISTING} exact />
					<AppRoute route={Routes.QUOTES_DETAILS} />
					<AppRoute route={Routes.QUOTES_LIST} />
					<AppRoute route={Routes.CASES} />
					<AppRoute route={Routes.CASE_DETAILS} />
					<AppRoute route={Routes.QUOTES_LIST} exact />
					<AppRoute route={Routes.RENEWALS_VALIDATION_RULES} />
					<AppRoute route={Routes.RENEWALS_TRANSFORMATION_RULES} />
					<AppRoute route={Routes.LEGAL_INFO} />
					<AppRoute route={Routes.PRIVATE_WILDCARD} />
				</Private>
			</AppZones>
		)
	);
};

export default App;
